body { background-color: $COL2; }
body { color: $COL3; }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
color: $COL15;
font-family: 'Roboto';
font-weight: 500;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 25px;

@media #{$medium-up} {
font-size: 40px;

}
}
h2 {
color: $COL16;
font-family: 'Roboto';
font-weight: 500;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 22px;

@media #{$medium-up} {
font-size: 35px;

}
}
h3 {
color: $COL17;
font-family: 'Roboto';
font-weight: 500;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 30px;

}
}
h4 {
color: $COL18;
font-family: 'Roboto';
font-weight: 500;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
h5 {
color: $COL19;
font-family: 'Roboto';
font-weight: 500;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 17px;

@media #{$medium-up} {
font-size: 20px;

}
}
h6 {
color: $COL20;
font-family: 'Roboto';
font-weight: 500;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
.button {
font-family: 'Roboto';
font-weight: 500;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
@media #{$large-up} {
font-size: 20px;

}
}
.me-Quote .quote-body {
font-family: 'Arapey';
font-weight: normal;
font-style: italic;
line-height: 1.5;
font-size: 18px;

@media #{$medium-up} {
font-size: 20px;

}
@media #{$large-up} {
font-size: 25px;

}
}
.me-Quote .quote-author {
font-family: 'Roboto';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 16px;

}
ol,ul {
color: $COL21;
font-family: 'Roboto';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
font-family: 'Roboto';
font-weight: normal;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 24px;

@media #{$medium-up} {
font-size: 30px;

}
}
summary {
font-family: 'Roboto';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Roboto';
font-weight: 500;
font-style: normal;
line-height: 1.5;
font-size: 16px;

}
nav.mainmenu > .menu-item > div > a {
font-family: 'Roboto';
font-weight: 500;
font-style: normal;
line-height: 1.5;
font-size: 16px;

}
nav.secondarymenu > .menu-item > a {
font-family: 'Roboto';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

}
nav.me-RespMenu.responsive-menu a {
font-family: 'Roboto';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 20px;

}
body {
font-family: 'Roboto';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 18px;

}
p { padding-bottom: 1em; }
a {color: $COL11}
a:hover {color: $COL12}
/* Primary:2 */
.MES2 {
background-color: $COL5;
color: $COL6;
 }
/* Primary:3 */
.MES3 {
background-color: $COL5;
color: $COL6;
 }
.MES3 {
background-color: $COL5;
color: $COL6;
h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: $COL6;
 }
 }
a.MEC3 { color: $COL23;
&:hover { color: $COL2; }
 }
cite.MEC3{
color: $COL6;
}
/* Secondary:4 */
.MES4 {
background-color: $COL7;
color: $COL8;
 }
/* Secondary:5 */
.MES5 {
background-color: $COL7;
color: $COL8;
 }
.MES5 {
background-color: $COL7;
color: $COL8;
h1.MEC5, h2.MEC5, h3.MEC5, h4.MEC5, h5.MEC5, h6.MEC5 { color: $COL8;
 }
 }
cite.MEC5{
color: $COL8;
}
/* Alternate:6 */
.MES6 {
background-color: $COL9;
color: $COL10;
 }
/* Alternate:7 */
.MES7 {
background-color: $COL9;
color: $COL10;
 }
.MES7 {
background-color: $COL9;
color: $COL10;
h1.MEC7, h2.MEC7, h3.MEC7, h4.MEC7, h5.MEC7, h6.MEC7 { color: $COL10;
 }
 }
a.MEC7 { color: $COL23;
&:hover { color: $COL23; }
 }
cite.MEC7{
color: $COL10;
}
/* Light:8 */
.MES8 {
background-color: $COL13;
color: $COL14;
 }
/* Light:9 */
.MES9 {
background-color: $COL13;
color: $COL14;
 }
.MES9 {
background-color: $COL13;
color: $COL14;
h1.MEC9, h2.MEC9, h3.MEC9, h4.MEC9, h5.MEC9, h6.MEC9 { color: $COL14;
 }
 }
cite.MEC9{
color: $COL14;
}
/* Top Panel:10 */
.MES10 {
background-color: $COL2;
color: $COL22;
 }
.MES10 {
background-color: $COL2;
color: $COL22;
 }
cite.MEC10{
color: $COL22;
}
/* Main Menu:11 */
nav.me-Menu.MES11 {
& .menu-item.MEC11, & .menu-item.MEC11 > div.MEC11{ & > a.MEC11{color: $COL22;
text-transform: uppercase;
}
 &:hover > a.MEC11{color: $COL7;
}
 }
&.horizontal > .menu-item.MEC11 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC11 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC11 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC11 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 15px 11px;}

& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Banner Mask Panel:13 */
.MES13 {
color: $COL2;
font-size: 21.6px;
@media #{$medium-up} {
font-size: 25.2px;
};
 }
.MES13 {
color: $COL2;
font-size: 21.6px;
@media #{$medium-up} {
font-size: 25.2px;
};
h1.MEC13, h2.MEC13, h3.MEC13, h4.MEC13, h5.MEC13, h6.MEC13 { color: $COL23;
 }
h1.MEC13 { @media #{$large-up} { font-size: 60px; }; }
h2.MEC13 { @media #{$large-up} { font-size: 52.5px; }; }
h3.MEC13 { @media #{$large-up} { font-size: 45px; }; }
h4.MEC13 { @media #{$large-up} { font-size: 37.5px; }; }
h5.MEC13 { @media #{$large-up} { font-size: 30px; }; }
h6.MEC13 { @media #{$large-up} { font-size: 27px; }; }
 }
cite.MEC13{
color: $COL2;
font-size: 21.6px;
@media #{$medium-up} {
font-size: 25.2px;
};
}
/* Top Quote Button:12 */
.MES12 {
background-color: $COL5;
&:hover { background-color: $COL9;}
color: $COL23;
&:hover { color: $COL23;}
padding: 5px 15px;

 }
/* Shade 1:14 */
.MES14 {
background-color: $COL24;
color: $COL25;
 }
/* Shade 1:15 */
.MES15 {
background-color: $COL24;
color: $COL25;
 }
.MES15 {
background-color: $COL24;
color: $COL25;
h1.MEC15, h2.MEC15, h3.MEC15, h4.MEC15, h5.MEC15, h6.MEC15 { color: $COL25;
 }
 }
cite.MEC15{
color: $COL25;
}
/* Credit Panel:16 */
.MES16 {
background-color: $COL28;
color: $COL22;
font-size: 14.4px;
@media #{$medium-up} {
font-size: 12.6px;
};
 }
.MES16 {
background-color: $COL28;
color: $COL22;
font-size: 14.4px;
@media #{$medium-up} {
font-size: 12.6px;
};
 }
cite.MEC16{
color: $COL22;
font-size: 14.4px;
@media #{$medium-up} {
font-size: 12.6px;
};
}
/* Service Block Panel:17 */
.MES17 {
border-width: 1px;
border-style: solid;
border-color: $COL24;
 }
.MES17 {
border-width: 1px;
border-style: solid;
border-color: $COL24;
 }
/* Footer Menu:18 */
nav.me-Menu.MES18 {
& .menu-item.MEC18, & .menu-item.MEC18 > div.MEC18{ & > a.MEC18{color: $COL23;
text-transform: uppercase;
}
 &:hover > a.MEC18{color: $COL2;
}
 }
&.horizontal > .menu-item.MEC18 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC18 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC18 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC18 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 5px 15px;}

& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Button 1:19 */
.MES19 {
background-color: $COL9;
&:hover { background-color: $COL7;}
color: $COL10;
&:hover { color: $COL2;}
border-radius: 10px;
padding: 20px 30px;

border-width: 4px;
border-style: solid;
border-color: $COL2;
 }
/* Dark:20 */
.MES20 {
background-color: $COL26;
 }
/* Dark:21 */
.MES21 {
background-color: $COL26;
 }
.MES21 {
background-color: $COL26;
 }
/* Button 2:22 */
.MES22 {
background-color: $COL7;
&:hover { background-color: $COL9;}
color: $COL2;
&:hover { color: $COL2;}
border-radius: 10px;
padding: 20px 40px;

border-width: 4px;
border-style: solid;
border-color: $COL2;
 }
/* Grey overlay:23 */
.MES23 {
background-color: $COL24;
& > .overlay, & > .panel-link { 
  &:hover, &.hover {background-color: $COL27;}}
& > .overlay {
position: absolute;
top:0;
bottom:0;
left: 0;
right: 0;
z-index: 1;
}
color: $COL25;
 }
.MES23 {
background-color: $COL24;
& > .overlay, & > .panel-link { 
  &:hover, &.hover {background-color: $COL27;}}
& > .overlay {
position: absolute;
top:0;
bottom:0;
left: 0;
right: 0;
z-index: 1;
}
color: $COL25;
h1.MEC23, h2.MEC23, h3.MEC23, h4.MEC23, h5.MEC23, h6.MEC23 { color: $COL25;
 }
&:hover { h1.MEC23, h2.MEC23, h3.MEC23, h4.MEC23, h5.MEC23, h6.MEC23 { color: $COL2; } }
 }
cite.MEC23{
color: $COL25;
}
/* Footer colour:24 */
.MES24 {
background-color: $COL9;
color: $COL10;
border-width: 10px 0 0 0;
border-style: solid;
border-color: $COL28 transparent $COL1 transparent;
 }
.MES24 {
background-color: $COL9;
color: $COL10;
border-width: 10px 0 0 0;
border-style: solid;
border-color: $COL28 transparent $COL1 transparent;
h1.MEC24, h2.MEC24, h3.MEC24, h4.MEC24, h5.MEC24, h6.MEC24 { color: $COL10;
 }
 }
a.MEC24 { color: $COL23;
&:hover { color: $COL2; }
 }
cite.MEC24{
color: $COL10;
}
/* Top banner border:25 */
.MES25 {
border-width: 0 0 5px 0;
border-style: solid;
border-color: transparent transparent $COL28 transparent;
 }
.MES25 {
border-width: 0 0 5px 0;
border-style: solid;
border-color: transparent transparent $COL28 transparent;
 }
/* Testimonial Panel:26 */
.MES26 {
background-color: $COL24;
padding: 30px 0;

@media #{$medium-up} {
padding: 40px 0;

}
@media #{$large-up} {
padding: 60px 0;

}
 }
.MES26 {
background-color: $COL24;
padding: 30px 0;

@media #{$medium-up} {
padding: 40px 0;

}
@media #{$large-up} {
padding: 60px 0;

}
 }
/* Separator line:27 */
.MES27 {
padding: 20px 0;

& > hr {border-width: 10px 0 0 0;}
& > hr {border-color: $COL28;}
& > hr {border-top-style: solid;}
 }
/* Footer stand out:28 */
.MES28 {
background-color: $COL5;
color: $COL2;
font-size: 16.2px;
@media #{$medium-up} {
font-size: 14.4px;
};
 }
.MES28 {
background-color: $COL5;
color: $COL2;
font-size: 16.2px;
@media #{$medium-up} {
font-size: 14.4px;
};
h1.MEC28, h2.MEC28, h3.MEC28, h4.MEC28, h5.MEC28, h6.MEC28 { color: $COL6;
 }
 }
a.MEC28 { color: $COL23;
&:hover { color: $COL2; }
 }
cite.MEC28{
color: $COL2;
font-size: 16.2px;
@media #{$medium-up} {
font-size: 14.4px;
};
}
/* Call out panel:29 */
.MES29 {
background-color: $COL7;
color: $COL2;
 }
.MES29 {
background-color: $COL7;
color: $COL2;
h1.MEC29, h2.MEC29, h3.MEC29, h4.MEC29, h5.MEC29, h6.MEC29 { color: $COL6;
 }
 }
a.MEC29 { color: $COL2;
&:hover { color: $COL23; }
 }
cite.MEC29{
color: $COL2;
}
/* Responsive Menu:30 */
nav.responsive-menu {
.menu-item.MEC30{background-color: $COL29;
}
& .menu-item.MEC30, & .menu-item.MEC30 > div.menu-item-wrap{ & > a.MEC30, & > i{color: $COL2;
font-size: 21.6px;
@media #{$medium-up} {
font-size: 18px;
}text-transform: uppercase;
text-align: center;
}
  }
& .menu-item.MEC30 { border:0;

 border-bottom-width: 1px; }
& .menu-item > .menu-item-wrap {padding: 8px 15px;}

& .sub-menu{ .sub-menu {}}

 }
/* PlaceHolder:31 */
.MES31 {
padding: 70px 0;

@media #{$large-up} {
padding: 0;

}
 }
