$COL1: rgba(0,0,0,0); //
$COLFLAT1: #808080; //
$COL2: #ffffff; //
$COLFLAT2: #ffffff; //
$COL3: #1b1b1b; //
$COLFLAT3: #1b1b1b; //
$COL4: #1b1b1b; //
$COLFLAT4: #1b1b1b; //
$COL5: #1f3e00; //
$COLFLAT5: #1f3e00; //
$COL6: #e8e800; //
$COLFLAT6: #e8e800; //
$COL7: #509f00; //
$COLFLAT7: #509f00; //
$COL8: #e8e800; //
$COLFLAT8: #e8e800; //
$COL9: #2e5b00; //
$COLFLAT9: #2e5b00; //
$COL10: #e8e800; //
$COLFLAT10: #e8e800; //
$COL11: #2e5b00; //
$COLFLAT11: #2e5b00; //
$COL12: #509f00; //
$COLFLAT12: #509f00; //
$COL13: #009f4d; //
$COLFLAT13: #009f4d; //
$COL14: #e8e800; //
$COLFLAT14: #e8e800; //
$COL15: #1b1b1b; //
$COLFLAT15: #1b1b1b; //
$COL16: #1b1b1b; //
$COLFLAT16: #1b1b1b; //
$COL17: #1b1b1b; //
$COLFLAT17: #1b1b1b; //
$COL18: #1b1b1b; //
$COLFLAT18: #1b1b1b; //
$COL19: #1b1b1b; //
$COLFLAT19: #1b1b1b; //
$COL20: #ffffff; //
$COLFLAT20: #ffffff; //
$COL21: #1b1b1b; //
$COLFLAT21: #1b1b1b; //
$COL22: #1b1b1b; //black
$COLFLAT22: #1b1b1b; //black
$COL23: #e8e800; //black
$COLFLAT23: #e8e800; //black
$COL24: #f2f2f2; //
$COLFLAT24: #f2f2f2; //
$COL25: #1b1b1b; //
$COLFLAT25: #1b1b1b; //
$COL26: #1b1b1b; //
$COLFLAT26: #1b1b1b; //
$COL27: rgba(27,27,27,0.271); //black transparency
$COLFLAT27: #656565; //black transparency
$COL28: #e8e8e8; //black transparency
$COLFLAT28: #e8e8e8; //black transparency
$COL29: rgba(46,91,0,0.8); //Green 80%
$COLFLAT29: #3e621a; //Green 80%
$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1248px,
  xxlarge: 1936px,
);
$global-width: 1280px;
